// @ts-check
import React from "react"

const ImageHeader = ({
  imgSrc,
  title,
  lead,
  noGradient = false,
  wave = false,
}) => {
  return (
    <div
      className="wrapper image-wrapper bg-image page-title-wrapper inverse-text"
      data-image-src={imgSrc}
    >
      {!noGradient && <div className="welcome-bg"></div>}
      <div className="container inner text-center">
        <div className="space90"></div>
        <h1 className="page-title">{title}</h1>
        {lead && <p className="lead">{lead}</p>}
      </div>

      {wave && (
        <div className="divider">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="fill-white-wrapper"
            preserveAspectRatio="none"
            viewBox="0 0 1070 20.98"
          >
            <path d="M0,0V21H1070V0A6830.24,6830.24,0,0,1,0,0Z" />
          </svg>
        </div>
      )}
    </div>
  )
}

export default ImageHeader
