// @ts-check
import React, { useEffect } from "react"
import "../../styles/loader.css"
import $ from "jquery"

const Loader = () => {
  useEffect(() => {
    $(".loader-wrapper").animate(
      {
        opacity: "0",
      },
      600,
      function() {
        setTimeout(function() {
          $(".loader-wrapper")
            .css("visibility", "hidden")
            .fadeOut()
        }, 300)
      }
    )
  }, [])

  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div className="diamond"></div>
        <div className="diamond"></div>
        <div className="diamond"></div>
      </div>
    </div>
  )
}

export default Loader
