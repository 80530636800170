// @ts-check
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import $ from "jquery"

import Nav from "./Nav"
import Footer from "./Footer"
import Loader from "./Loader"
import SEO from "../seo"

const Layout = ({ children, transparentHeader = false, title = " " }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    $(".bg-image").css("background-image", function() {
      var bg = "url(" + $(this).data("image-src") + ")"
      return bg
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <SEO title={title} />
      <div className="content-wrapper">
        <Nav transparent={transparentHeader} />
        <main>{children}</main>
        <Footer />
      </div>
      <Loader />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
