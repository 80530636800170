// @ts-check

import React, { useEffect } from "react"
import $ from "jquery"
import Headhesive from "headhesive"
import classNames from "classnames"
import { Link } from "gatsby"

const Nav = ({ transparent }) => {
  useEffect(() => {
    if ($(".navbar").length) {
      var options = {
        offset: 350,
        offsetSide: "top",
        classes: {
          clone: "banner--clone fixed",
          stick: "banner--stick bg-white",
          unstick: "banner--unstick",
        },
        onStick: function() {
          //   $($.SmartMenus.Bootstrap.init)
          // do nothing
        },
        onUnstick: function() {
          //   $(".search-dropdown .dropdown-menu").collapse("hide")
        },
      }

      new Headhesive(".navbar", options)
    }

    var $header_hamburger = $(".hamburger.animate")
    var $header_search = $(".search-dropdown .dropdown-menu")
    var $header_cart = $(".cart-dropdown .dropdown-menu")
    var $navbar_offcanvas = $(".offcanvas-nav")
    var $navbar_offcanvas_toggle = $('[data-toggle="offcanvas-nav"]')
    var $navbar_offcanvas_close = $(".offcanvas-nav-close")
    var $navbar_search_toggle = $(".search-dropdown .collapse-toggle")
    var $navbar_search_close = $(
      ".search-dropdown .dropdown-menu .dropdown-close"
    )
    var $info_offcanvas = $(".offcanvas-info")
    var $info_offcanvas_close = $(".offcanvas-info-close")
    var $info_offcanvas_toggle = $('[data-toggle="offcanvas-info"]')
    $header_hamburger.on("click", function() {
      $header_hamburger.toggleClass("active")
      $header_search.collapse("hide")
    })
    $header_search.on("click", function(e) {
      e.stopPropagation()
    })
    $navbar_search_close.click(function() {
      $header_search.collapse("hide")
    })
    $navbar_search_toggle.on("click", function(e) {
      $navbar_offcanvas.removeClass("open")
      $header_hamburger.removeClass("active")
    })
    $navbar_offcanvas_toggle.on("click", function(e) {
      e.stopPropagation()
      $navbar_offcanvas.toggleClass("open")
    })
    $navbar_offcanvas.on("click", function(e) {
      e.stopPropagation()
    })
    $header_cart.on("click", function(e) {
      e.stopPropagation()
    })
    $navbar_offcanvas_close.on("click", function(e) {
      $navbar_offcanvas.removeClass("open")
      $header_hamburger.removeClass("active")
    })
    $info_offcanvas_toggle.on("click", function(e) {
      e.stopPropagation()
      $info_offcanvas.toggleClass("open")
      $header_search.collapse("hide")
    })
    $info_offcanvas.on("click", function(e) {
      e.stopPropagation()
    })
    $(document).on("click", function() {
      $header_search.collapse("hide")
      $navbar_offcanvas.removeClass("open")
      $info_offcanvas.removeClass("open")
      $header_hamburger.removeClass("active")
    })
    $info_offcanvas_close.on("click", function(e) {
      $info_offcanvas.removeClass("open")
    })
    $(".onepage .navbar li a").on("click", function() {
      $navbar_offcanvas.removeClass("open")
      $header_hamburger.removeClass("active")
    })
  }, [])

  const dynamicClasses = classNames({
    "bg-dark": !transparent,
  })

  return (
    <nav
      className={`navbar transparent absolute inverse-text navbar-hover-opacity nav-uppercase navbar-expand-lg ${dynamicClasses}`}
    >
      <div className="container flex-row justify-content-center">
        <div className="navbar-brand">
          <a href="/">
            <img
              style={{ width: 180 }}
              src="#"
              srcSet="/images/logo-dark.png 1x, /images/logo-dark@2x.png 2x"
              className="logo-dark"
              alt=""
            />
            <img
              src="#"
              style={{ width: 180 }}
              srcSet="/images/logo-light.png 1x, /images/logo-light@2x.png 2x"
              className="logo-light"
              alt=""
            />
          </a>
        </div>
        <div className="navbar-other ml-auto order-lg-3">
          <ul
            className="navbar-nav flex-row align-items-center"
            data-sm-skip="true"
          >
            <li className="nav-item">
              <div className="navbar-hamburger d-lg-none d-xl-none ml-auto">
                <button
                  className="hamburger animate plain"
                  data-toggle="offcanvas-nav"
                >
                  <span></span>
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div className="navbar-collapse offcanvas-nav">
          <div className="offcanvas-header d-lg-none d-xl-none">
            <a href="/">
              <img
                src="#"
                srcSet="/images/logo-light.png 1x, /images/logo-light@2x.png 2x"
                alt=""
              />
            </a>
          </div>
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav
